import { initializeApp } from "firebase/app";

export const firebaseConfig = {
    apiKey: "AIzaSyCgtcKhHMSXI7pCKvv4kr4tW0fIb3DeGg4",
    authDomain: "hervest-9f32d.firebaseapp.com",
    databaseURL: "https://hervest-9f32d.firebaseio.com",
    projectId: "hervest-9f32d",
    storageBucket: "hervest-9f32d.appspot.com",
    messagingSenderId: "682816786384",
    appId: "1:682816786384:web:ef8b176f206ca712db73d5",
    measurementId: "G-Q12ZVK3C0H"
  };
  
  // Initialize Firebase
  export const fbAnalyticsApp = initializeApp(firebaseConfig);
  