import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./Pages/Home";
import LandingPage from "./Pages/LandingPage";
import GuarantorForm from "./components/Forms/GuarantorForm";
import AcceptOffer from "./Pages/AcceptOffer";
import Review from "./Pages/Review";
import SuccessPage from "./Pages/Success";
import { Toaster } from "react-hot-toast";
import NewLandingPage from "./Pages/NewLandingPage";
import { useSelector } from "react-redux";
// import * as amplitude from '@amplitude/analytics-browser';

const App = () => {
  const allLoanData = useSelector((state) => state.loanData);

  console.log(allLoanData, "ALL THE LOAN DATA HERE");
  useEffect(() => {
    const customerSupportChatDetailsPrefilled = () => {
      if (allLoanData.email) {
        window.Beacon("identify", {
          name: `${allLoanData?.first_name}${" "}${allLoanData?.last_name}`,
          email: `${allLoanData?.email}`,
          "mobile-number": `${allLoanData?.phone}`,
          "nin-number": `${allLoanData?.nin}`,
          "bvn-number": `${allLoanData?.bvn}`,
          "date-of-birth": `${allLoanData?.dob}`,
          "loan-application-type": `${allLoanData?.type}`,
          "loan-application-amount": `${allLoanData?.amount}`,
        });
      }
    };
    customerSupportChatDetailsPrefilled();
  }, [allLoanData]);

  return (
    <>
      <Router>
        <Routes>
          <Route exact={true} path="/request/:id" element={<Home />}></Route>
          <Route exact={true} path="/" element={<NewLandingPage />}></Route>

          <Route exact={true} path="/home" element={<LandingPage />}></Route>
          <Route
            exact={true}
            path="/accept_offer/:loanid"
            element={<AcceptOffer />}
          ></Route>

          <Route
            exact={true}
            path="/guarantor/:userid"
            element={<GuarantorForm />}
          ></Route>

          <Route exact={true} path="/review/:id" element={<Review />}></Route>
          <Route exact={true} path="/success" element={<SuccessPage />} />
        </Routes>
      </Router>

      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "#5B2E4F",
              color: "white",
            },
          },
          error: {
            style: {
              background: "#5B2E4F",
              color: "white",
            },
          },
        }}
      />
    </>
  );
};

export default App;
