import {
  Box,
  Flex,
  Text,
  Divider,
  HStack,
  useToast,
  Button,
  Checkbox,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { requestLoan, updateField, editLoan } from "../../features/loanSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import { fbAnalyticsApp } from "../../fbanalytics";
import * as amplitude from '@amplitude/analytics-browser';

const PreviewDetails = ({ setCurrentScreen }) => {
  const allLoanData = useSelector((state) => state.loanData);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { isLoading, isError, allData, isEditing, ...requestData } =
    allLoanData;

  const toast = useToast();
  const dispatch = useDispatch();
  const [checkedItem, setcheckedItem] = useState(false);
  const request_id = localStorage.getItem("request_id");
  // const [verifiedNin, setVerifiedNin] = useState(false);

  //VERIFY NIN BEFORE FORM SUBMISSION
  // const verifyNin = async () => {
  //   try {
  //     const { data } = await axios.get(
  //       `${API_BASE_URL}/misc/verify-nin?nin=${allLoanData.nin}`
  //     );

  //     if (
  //       data.data.firstname.toLowerCase().trim() ===
  //       allLoanData.first_name.toLowerCase().trim()
  //     ) {
  //       if (
  //         data.data.surname.toLowerCase().trim() ===
  //         allLoanData.last_name.toLowerCase().trim()
  //       ) {
  //         setVerifiedNin(true);
  //         return;
  //       } else {
  //         setVerifiedNin(false);
  //       }
  //     } else {
  //       setVerifiedNin(false);
  //     }
  //   } catch (error) {
  //     setVerifiedNin(false);
  //   }
  // };

  //LOAN SUBMISSION HANDLER
  const submitBtn = async () => {

    const analytics = getAnalytics(fbAnalyticsApp);

    let userInfo = {
      completed: "true",
      // is_nin_verified: verifiedNin,
    };

    if (!checkedItem) {
      toast({
        title: "Error submitting",
        description: "Please check the box to accept the T&C",
        position: "top-left",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    dispatch(updateField(userInfo));

    const updatedData = { ...requestData, ...userInfo };
    setLoading(true);
    if (request_id) {
      // await verifyNin();
      dispatch(editLoan(updatedData)).then((res) => {
        if (res?.payload?.status === true) {
          toast({
            title: "Loan successfully requested",
            description:
              "You have successfuly submitted your loan request and it will be reviewed",
            position: "top-left",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          navigate(
            `/success?id=${
              localStorage.getItem("request_id") || res.payload.data.request_id
            }`
          );
          
          logEvent(analytics, 'SUCCESSFUL_LOAN_APPLICATION', {
            "loan_request_id": localStorage.getItem("request_id") || res.payload.data.request_id
          });

          amplitude.track("SUCCESSFUL_LOAN_APPLICATION", {
            "loan_request_id": localStorage.getItem("request_id") || res.payload.data.request_id
          });
          
          return;
        } else {
          toast({
            title: "Loan Error",
            description: "An error occurred. Please try again later",
            position: "top-left",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
          
          logEvent(analytics, 'FAILED_LOAN_APPLICATION', {
            "loan_request_id": localStorage.getItem("request_id") || res.payload.data.request_id
          });
        }
      });
      setLoading(false);
      return;
    }

    // await verifyNin();
    setLoading(true);
    dispatch(requestLoan(updatedData)).then((res) => {
      // await verifyNin();
      if (res?.payload?.status === true) {
        toast({
          title: "Loan successfully requested",
          description:
            "You have successfuly submitted your loan request and it will be reviewed",
          position: "top-left",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        navigate(`/success?id=${res.payload.data.request_id}`);
        return;
      } else {
        setLoading(false);
        toast({
          title: "Loan Error",
          description: "An error occurred. Please try again later",
          position: "top-left",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    });
  };

  const goBack = () => {
    setCurrentScreen(4);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Flex Flex justify="center">
      <Box
        Box
        w={["100%", "80%", "60%"]}
        boxShadow="0px 4px 12px 0px #D7D7D740"
        border="0.5px solid #EFEAED"
        borderRadius="10px"
      >
        <Text
          p="20px"
          fontSize={["12px", "14px"]}
          fontWeight={["500", "700"]}
          color="#33343D"
        >
          Personal Information
        </Text>
        <Divider mt="5px" height="0.4px" bg="#5B2E4F" width="100%" />
        <Flex align="baseline" flexWrap="wrap" w="100%" p="20px">
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              First Name
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.first_name}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              last Name
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.last_name}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Maiden Name
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.alias || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              BVN
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.bvn || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Sex
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.gender || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Marital Status
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.marital_status || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Date of birth
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.dob || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Phone Number
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.phone || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Alternate Phone Number
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.alternate_phone || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Email Address
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.email || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Residential Address
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.address || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Nearest landmark
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.landmark || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Residential Status
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.home_ownership || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Signature
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.signatureName || "-"}
            </Text>
            {/* <Img src={allLoanData?.signature[0]} alt="signature"/> */}
          </Box>

          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px"></Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.home_ownership || "-"}
            </Text>
          </Box>
        </Flex>
        <Text
          p="20px"
          fontSize={["12px", "14px"]}
          fontWeight={["500", "700"]}
          color="#33343D"
        >
          Employer's Information
        </Text>
        <Divider mt="10px" height="0.5px" bg="#5B2E4F" width="100%" />
        <Flex align="baseline" flexWrap="wrap" p="20px">
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Occupation
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.occupation || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Designation
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.designation || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Employer's Name
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.employer || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Office Telephone Number
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.office_no || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Alternative Phone Number
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.office_alternate_no || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Office Email Address
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.office_email || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Office Business Address
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.office_address || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Loan Amount
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.amount || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Loan Tenure
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.tenure || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Purpose of Loan Request
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.purpose || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              NIN
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.nin || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Bank
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.bank_name || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Account Number
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.account_name || "-"}
            </Text>
          </Box>
        </Flex>
        <Text
          p="20px"
          fontSize={["12px", "14px"]}
          fontWeight={["500", "700"]}
          color="#33343D"
        >
          Uploaded Documents
        </Text>
        <Divider height="0.5px" bg="#5B2E4F" width="100%" />
        <Flex align="baseline" mt="10px" p="20px" flexWrap="Wrap">
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Utility Bill
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.utilityName || "-"}
            </Text>
            {/* <a target="_blank" rel="noreferrer" href={allLoanData?.utility_bill}><Text color="#33343D" fontWeight="500" fontSize="13px" opacity="80%">{allLoanData?.utilityName || "-"}</Text></a> */}
          </Box>
          <Box p="10px">

            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              PassPort Photograph
            </Text>

            {/* <a target="_blank" rel="noreferrer" href={allLoanData?.passport}> */}

            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.passportName || "-"}
            </Text>

            {/* </a> */}
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Government ID
            </Text>
            {/* <a target="_blank" rel="noreferrer" href={allLoanData?.government_id}> */}
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.idName || "-"}
            </Text>
            {/* </a> */}
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Bank Statement
            </Text>
            {/* <a target="_blank" rel="noreferrer" href={allLoanData?.bank_statement}> */}
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.bankStatementName || "-"}
            </Text>
            {/* </a> */}
          </Box>
          {allLoanData?.cacName && (
            <Box p="10px">
              <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                CAC Document
              </Text>
              {/* <a target="_blank" rel="noreferrer" href={allLoanData?.cac}> */}
              
              <Text
                color="#33343D"
                fontWeight="500"
                fontSize="13px"
                opacity="80%"
              >
                {allLoanData?.cacName || "-"}
              </Text>
              {/* </a> */}
            </Box>
          )}
        </Flex>
        <Text
          p="20px"
          fontSize={["12px", "14px"]}
          fontWeight={["500", "700"]}
          color="#33343D"
        >
          Next of Kin's Information
        </Text>
        <Divider height="0.5px" bg="#5B2E4F" width="100%" />
        <Flex align="baseline" mt="10px" p="20px" flexWrap="Wrap">
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Full Name
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.nok_name || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Phone Number
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.nok_phone || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Date of birth
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.nok_dob || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Place of work
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.nok_place_of_work || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Occupation
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.nok_occupation || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Email Address
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.nok_email || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Relationship to borrower
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.nok_relationship || "-"}
            </Text>
          </Box>
          <Box p="10px">
            <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
              Office Business Address
            </Text>
            <Text
              color="#33343D"
              fontWeight="500"
              fontSize="13px"
              opacity="80%"
            >
              {allLoanData?.nok_office_address || "-"}
            </Text>
          </Box>
        </Flex>
        {allLoanData?.guarantor_name && (
          <>
            <Text
              p="20px"
              fontSize={["12px", "14px"]}
              fontWeight={["500", "700"]}
              color="#33343D"
            >
              Guarantor
            </Text>
            <Divider height="0.5px" bg="#5B2E4F" width="100%" />
            <Flex align="baseline" mt="10px" p="20px" flexWrap="Wrap">
              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Guarantor Name
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.guarantor_name || "-"}
                </Text>
              </Box>

              <Box p="10px">
                <Text color="#5B2E4F" fontWeight="500" fontSize="15px">
                  Guarantor Name
                </Text>
                <Text
                  color="#33343D"
                  fontWeight="500"
                  fontSize="13px"
                  opacity="80%"
                >
                  {allLoanData?.guarantor_email || "-"}
                </Text>
              </Box>
            </Flex>
          </>
        )}

        <Checkbox
          isChecked={checkedItem}
          mt="10px"
          ml="20px"
          fontSize="10px"
          onChange={(e) => setcheckedItem(e.target.checked)}
        >
          Please note that you won't be able to edit your details after this
          final submission
        </Checkbox>

        <Divider mt="30px" height="0.4px" bg="#5B2E4F" width="100%" />
        <HStack justify="flex-end" spacing={3} mt="20px">
          <Button
            bg="white"
            borderRadius="6px"
            fontSize="12px"
            fontWeight="500"
            color="#5B2E4F"
            border="1px solid #5B2E4F"
            p={["8px 32px", "13px 52px"]}
            _hover={{
              color: "white",
              border: "1px solid #5B2E4F",
              bg: "#5B2E4F",
            }}
            onClick={goBack}
          >
            BACK
          </Button>

          {isLoading || loading ? (
            <Button
              bg="#5B2E4F"
              borderRadius="6px"
              fontSize="12px"
              fontWeight="500"
              color="white"
              p="13px 52px"
              _hover={{
                color: "#5B2E4F",
                border: "1px solid #5B2E4F",
                bg: "white",
              }}
              isLoading
              loadingText="Submitting"
            >
              SUBMIT
            </Button>
          ) : (
            <Button
              onClick={submitBtn}
              bg="#5B2E4F"
              borderRadius="6px"
              fontSize="12px"
              fontWeight="500"
              color="white"
              p="13px 52px"
              _hover={{
                color: "#5B2E4F",
                border: "1px solid #5B2E4F",
                bg: "white",
              }}
            >
              SUBMIT
            </Button>
          )}
        </HStack>
      </Box>
    </Flex>
  );
};

export default PreviewDetails;
