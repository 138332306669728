import {
  Box,
  Flex,
  HStack,
  Img,
  Text,
  Radio,
  RadioGroup,
  Divider,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "../components/Others/Navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetState, updateField } from "../features/loanSlice";
import { API_BASE_URL } from "../services/apiConstants";
import axios from "axios";
import ModalLayout from "../components/Layout/ModalLayout";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorMsg from "../components/Errors/ErrorMessage";
import IncompleteApplication from "../components/Modal/IncompleteApplication";
import LoginHervestUser from "../components/Modal/LoginWithHervestModal";
import moment from "moment";
import { GiFingerPrint } from "react-icons/gi";
import hervestIcon from "../assets/hervestIcon.svg";
import { cleanTextInputField } from "../services/utils/helpers";
import SelectLoanApplicationType from "../components/Modal/SelectLoanApplicationType";
import { useLocation } from "react-router-dom";
import authService from "../services/authAction";
import { getAnalytics, setUserProperties, logEvent } from "firebase/analytics";
import { fbAnalyticsApp } from "../fbanalytics";
import * as amplitude from '@amplitude/analytics-browser';

function EditModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setId] = useState("");
  // const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [isLoanCompleted, setIsLoanCompleted] = useState(false);

  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getDetails = async () => {
    setLoading(true);
    try {
      const resp = await axios.get(`${API_BASE_URL}/loans/requests/${id}`);

      const modified = modifyData(resp?.data?.data);
      setData(modified);

      if (resp?.data?.data?.completed === true) {
        toast({
          title: "Loan Request",
          description: "Your loan request has been completed",
          position: "top-left",
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
        setIsLoanCompleted(true);
        return;
      }

      toast({
        title: "Loan Request",
        description: resp?.data?.message,
        position: "top-left",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      localStorage.setItem("request_id", resp?.data?.data?.request_id);
      return resp?.data;
    } catch (error) {
      let message;
      if (error?.response?.status === 404) {
        message =
          "Loan Application with the ID doesn't exists. Please check and try again.";
      }

      toast({
        title: "Error",
        description:
          message ||
          error?.response?.data?.message ||
          "Something went wrong, please try again.",
        position: "top-left",
        status: "error",
        duration: 2000,
        isClosable: true,
      });

      onClose();
    } finally {
      setLoading(false);
      setId("");
    }
  };

  const editDetails = () => {
    if (isLoanCompleted) {
      navigate(`/review/${data.request_id}`);
      return;
    }

    const editingData = { ...data, isEditing: true };
    dispatch(updateField(editingData));
    navigate(`/request/${data.type}`);
  };

  return (
    <>
      <Button
        onClick={onOpen}
        bg="white"
        borderRadius="6px"
        fontSize="12px"
        fontWeight="500"
        color="#5B2E4F"
        border="1px solid #5B2E4F"
        p={["8px 32px", "13px 52px"]}
        _hover={{ color: "white", border: "1px solid #5B2E4F", bg: "#5B2E4F" }}
        textTransform="uppercase"
      >
        Existing Application
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={["12px", "16px"]}>
            Please enter your unique ID to complete your details
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {/* <FormLabel htmlFor="email">Unique ID</FormLabel> */}
              <Input
                value={id}
                type="text"
                onChange={(e) => {
                  setId(e.target.value);
                  setData("");
                }}
              />

              {/* <FormLabel htmlFor="email" mt="2">
                {" "}
                Email Address
              </FormLabel>
              <Input
                value={email}
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setData("");
                }}
              /> */}
            </Box>
            <Box mt="20px">
              {loading ? (
                <Button
                  isLoading
                  bg="#5B2E4F"
                  borderRadius="6px"
                  fontSize="12px"
                  fontWeight="500"
                  color="white"
                  p="13px 32px"
                  _hover={{
                    color: "#5B2E4F",
                    border: "1px solid #5B2E4F",
                    bg: "white",
                  }}
                  textTransform="uppercase"
                >
                  search
                </Button>
              ) : (
                <Button
                  bg="#5B2E4F"
                  borderRadius="6px"
                  fontSize="12px"
                  fontWeight="500"
                  color="white"
                  p="13px 32px"
                  _hover={{
                    color: "#5B2E4F",
                    border: "1px solid #5B2E4F",
                    bg: "white",
                  }}
                  onClick={getDetails}
                  textTransform="uppercase"
                >
                  search
                </Button>
              )}
            </Box>
          </ModalBody>

          <ModalFooter display="flex" justifyContent="flex-start">
            <Button
              variant="ghost"
              color="#5B2E4F"
              textDecoration="underline"
              onClick={editDetails}
            >
              {data?.completed &&
                "click here to review and track application status"}
              {data?.completed === false &&
                "click here to continue application"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

const LandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("business");
  const [verifyBvn, setVerifyBvn] = useState(false);
  const [loginWithHervest, setLoginWithHervest] = useState(false);
  const allLoanData = useSelector((state) => state.loanData);
  const [incompleteApplication, setIncompleteApplication] = useState(false);
  const [trials, setTrials] = useState(0);
  const [proceedMethod, setProceedMethod] = useState("");
  const [error, setError] = useState("");
  const [token, setToken] = useState("");
  const [requestId, setRequestId] = useState("");
  const toast = useToast();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  useEffect(() => {
    const token = query.get("token");
    const requestId = query.get("request_id");
    setRequestId(requestId);
    setToken(token);
  }, [query]);

  console.log(requestId, "THE REQUEST ID");

  //TO AUTOLOGIN USER FROM HERVEST WEB APP WITH TOKEN TO CONTINUE APPLICATION
  useEffect(() => {
    const fetchUserDetails = async () => {
      const res = await authService.fetchUserInfo(token);
      if (res) {
        const userDetails = res?.user;
        dispatch(
          updateField({
            bvn: userDetails.bvn,
            last_name: userDetails?.lastName,
            email: userDetails?.email,
            first_name: userDetails?.firstName,
            dob: userDetails?.dob ? userDetails?.dob : "",
            alias: userDetails?.middle_name,
            phone: userDetails?.phoneNumber,
            nok_name: userDetails?.nok_name,
            nok_phone: userDetails?.nok_phone,
            nok_relationship: userDetails?.nok_relationship,
            // alternate_phone: userDetails?.phoneNumber2,
            // marital_status: userDetails?.maritalStatus.toLowerCase(),
            // nin: userDetails?.nin,
            state_of_origin: userDetails?.city,
            address: userDetails?.residentialAddress,
            is_bvn_verified: false,
          })
        );
        setOpenLoanTypeModal(true);
      }
    };
    if (token) {
      fetchUserDetails();
    }
  }, [token, dispatch]);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const resp = await axios.get(
          `${API_BASE_URL}/loans/requests/${requestId}`
        );

        const modified = modifyData(resp?.data?.data);
        if (resp?.data?.data?.completed === true) {
          navigate(`/review/${requestId}`);
          return;
        }

        toast({
          title: "Loan Request",
          description: resp?.data?.message,
          position: "top-left",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        const loanData = resp?.data?.data;
        dispatch(
          updateField({
            ...modified,
          })
        );

        localStorage.setItem("request_id", modified.request_id);
        navigate(`/request/${loanData.type}`);
      } catch (error) {
        let message;
        if (error?.response?.status === 404) {
          message =
            "Loan Application with the ID doesn't exists. Please check and try again.";
        }

        toast({
          title: "Error",
          description:
            message ||
            error?.response?.data?.message ||
            "Something went wrong, please try again.",
          position: "top-left",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } finally {
      }
    };

    if (requestId) {
      getDetails();
    }
  }, [requestId, dispatch, navigate, toast]);

  const [openLoanTypeModal, setOpenLoanTypeModal] = useState(false);

  /**
   * @description CHECK IF THE USER HAS BEEN INACTIVE FOR 24 HOURS
   * @description IF TRUE, CLEAR LOCAL STORAGE AND RESET STATE
   * @description ELSE, SET LAST ACCESSED DATE
   */
  useEffect(() => {
    const lastAccessed = localStorage.getItem("last_accessed");
    const currentDate = Date.now();
    if (lastAccessed) {
      const timeDifference = currentDate - lastAccessed;
      if (timeDifference >= 86400000) {
        localStorage.clear();
        dispatch(resetState());
      }
    }

    if (!lastAccessed) {
      localStorage.setItem("last_accessed", Date.now());
    }
  }, [dispatch]);

  //LAST APPLICATION ACCESSEE DATE
  const lastAccessed = localStorage.getItem("last_accessed");
  const currentDate = Date.now();

  const handleRadioChange = (event) => {
    setSelectedValue(event);
  };

  const proceedMethodHandler = () => {
    // dispatch(resetState())
    setError("");
    if (!proceedMethod) {
      setError("Please choose how you wish to proceed with your application");
      return;
    }

    dispatch(updateField({ type: selectedValue }));
    if (proceedMethod === "bvn") {

      setVerifyBvn(true);
      const analytics = getAnalytics(fbAnalyticsApp);
      logEvent(analytics, 'PROCEEDED_WITH_BVN');

      amplitude.track("PROCEEDED_WITH_BVN");

    } else {
      setLoginWithHervest(true);
      const analytics = getAnalytics(fbAnalyticsApp);
      logEvent(analytics, 'IS_AN_EXISTING_USER');
    }
  };

  const verifyBvnDetails = async (values, setSubmitting, setErrors) => {
    const verified_bvn = localStorage.getItem("verified_bvn");
    /**
     * @description A CHECK IF THE USER HD PREVIOUSLY TRIED VERIFYING HIS/HER BVN BEFORE ON THE SYSTEM
     */

    const analytics = getAnalytics(fbAnalyticsApp);

    if (verified_bvn === values.bvn) {


      const bvnData = JSON.parse(localStorage.getItem("verified_bvn_details"));
      dispatch(
        updateField({
          bvn: values.bvn,
          last_name: bvnData?.lastName,
          email: bvnData?.email,
          first_name: bvnData?.firstName,
          dob: moment(bvnData?.dateOfBirth, "DD-MMM-YYYY").format("YYYY-MM-DD"),
          alias: bvnData?.middleName,
          phone: bvnData?.phoneNumber1,
          alternate_phone: bvnData?.phoneNumber2,
          gender: bvnData?.gender?.toLowerCase(),
          marital_status: bvnData?.maritalStatus?.toLowerCase(),
          nin: bvnData?.nin,
          state_of_origin: bvnData?.stateOfOrigin,
          address: bvnData?.residentialAddress,
          is_bvn_verified: true,
        })
      );
      setSubmitting(false);
      // localStorage.setItem("last_accessed", Date.now());
      // navigate(`/request/${selectedValue}`);
      setVerifyBvn(false);
      setOpenLoanTypeModal(true);

      amplitude.init('1cafb74c75939be147329c4b00368d1f', bvnData?.email, { "autocapture": true });
      // amplitude.setUserId(bvnData?.email);
      // amplitude.setSessionId(Date.now());

      // const identifyEvent = new amplitude.Identify();
      // identifyEvent.set('first_name', bvnData?.firstName);
      // identifyEvent.set('last_name', bvnData?.lastName);
      // identifyEvent.set('email', bvnData?.email);
      // identifyEvent.set('phone', bvnData?.phoneNumber1);
      // identifyEvent.set('alternate_phone', bvnData?.phoneNumber2);

      // amplitude.identify(identifyEvent);

      setUserProperties(analytics, {
        "last_name": bvnData?.lastName,
        "email": bvnData?.email,
        "first_name": bvnData?.firstName,
        "phone": bvnData?.phoneNumber1,
        "alternate_phone": bvnData?.phoneNumber2,
        "dob": moment(bvnData?.dateOfBirth, "DD-MMM-YYYY").format("YYYY-MM-DD"),
      });

      logEvent(analytics, 'SUCCESSFULLY_VERIFIED_BVN', {
        "last_name": bvnData?.lastName,
        "email": bvnData?.email,
        "first_name": bvnData?.firstName,
        "phone": bvnData?.phoneNumber1,
        "alternate_phone": bvnData?.phoneNumber2,
        "dob": moment(bvnData?.dateOfBirth, "DD-MMM-YYYY").format("YYYY-MM-DD"),
      });




      return;
    }

    /**
     * @description IF THE USER HASN'T TRIED VERIFIYING HIS/HER BVN BEFORE
     * API CALL TO PREMBLY FOR VERIFICATION
     * **/

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/misc/verify-bvn?bvn=${values.bvn}`,
        config
      );
      if (data.status === false) {
        setTrials(trials + 1);

        if (trials === 2) {
          openLoanTypeModal(true);
          return;
          // return navigate(`/request/${selectedValue}`);
        }

        setErrors({
          bvn:
            `${data?.message}. Please check your BVN and try again.` ||
            "We are unable to verify your BVN. Please try again.",
        });
        return;
      }

      localStorage.setItem("verified_bvn", values.bvn);
      localStorage.setItem("verified_bvn_details", JSON.stringify(data.data));
      dispatch(
        updateField({
          bvn: values?.bvn,
          last_name: cleanTextInputField(data?.data?.lastName),
          email: data?.data?.email,
          first_name: cleanTextInputField(data?.data?.firstName),
          dob: moment(data?.data?.dateOfBirth, "DD-MMM-YYYY").format(
            "YYYY-MM-DD"
          ),
          alias: cleanTextInputField(data?.data?.middleName),
          phone: data?.data?.phoneNumber1,
          alternate_phone: data?.data?.phoneNumber2,
          gender: data?.data?.gender?.toLowerCase(),
          marital_status: data?.data?.maritalStatus?.toLowerCase(),
          nin: data?.data?.nin,
          state_of_origin: cleanTextInputField(data?.data?.stateOfOrigin),
          address: data?.data?.residentialAddress,
          is_bvn_verified: true,
        })
      );
      setSubmitting(false);
      // localStorage.setItem("last_accessed", Date.now());
      // navigate(`/request/${selectedValue}`);
      setVerifyBvn(false);
      setOpenLoanTypeModal(true);

      console.log("reached final point");
    } catch (error) {
      logEvent(analytics, 'FAILED_TO_VERIFIED_BVN');
      setSubmitting(false);
      setTrials(trials + 1);

      if (trials === 2) {
        // return navigate(`/request/${selectedValue}`);
        setOpenLoanTypeModal(true);
        return;
      }

      setErrors({
        bvn:
          error?.response?.data?.message ||
          "We are unable to verify your BVN. please try again",
      });
    }
  };

  useEffect(() => {
    const request_id = localStorage.getItem("request_id");

    if (request_id || allLoanData?.bvn) {
      setIncompleteApplication(true);
      return;
    }
  }, [allLoanData, dispatch, lastAccessed, currentDate]);

  return (
    <Box p={["20px 10px", "20px 30px"]}>
      <IncompleteApplication
        isOpen={incompleteApplication}
        setIsOpen={setIncompleteApplication}
        setOpenLoanTypeModal={setOpenLoanTypeModal}
      />
      <LoginHervestUser
        isOpen={loginWithHervest}
        setIsOpen={setLoginWithHervest}
        setVerifyBvn={setVerifyBvn}
        setOpenLoanTypeModal={setOpenLoanTypeModal}
      />
      <SelectLoanApplicationType
        isOpen={openLoanTypeModal}
        setIsOpen={setOpenLoanTypeModal}
      />
      <Formik
        initialValues={{
          bvn: "",
        }}
        validationSchema={Yup.object({
          bvn: Yup.string()
            .required("Bvn is Required")
            .min(11, "Must be 11 digits")
            .max(11, "Must be 11 digits"),
        })}
        onSubmit={(values, { setSubmitting, setErrors }) =>
          verifyBvnDetails(values, setSubmitting, setErrors)
        }
      >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <ModalLayout
            loading={isSubmitting}
            onClose={setVerifyBvn}
            submitHandler={handleSubmit}
            isOpen={verifyBvn}
            setIsOpen={setVerifyBvn}
            title="Please enter your BVN to Continue"
          >
            <form onSubmit={handleSubmit}>
              <Box marginY={"10px"}>
                <Text
                  paddingBottom={"10px"}
                  fontSize={["14px", "16px"]}
                  fontWeight={["500"]}
                  color="#33343D"
                  fontFamily="SatoshiBold"
                >
                  BVN
                </Text>
                <Input
                  id="bvn"
                  name="bvn"
                  className="form-input"
                  placeholder="Enter your 11 digits BVN"
                  value={values.bvn}
                  maxLength={11}
                  minLength={11}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                />
                <ErrorMsg name="bvn" />
              </Box>
            </form>
          </ModalLayout>
        )}
      </Formik>
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Box
          borderRadius={["10px"]}
          w={["90%", "80%", "60%"]}
          h={["60px", "70px", "70px"]}
          bgGradient="linear(to-r, #540A45, pink.500)"
        >
          <Text
            fontSize={["15px", "22px"]}
            color={"white"}
            textAlign={"center"}
            fontFamily="SatoshiBold"
            paddingTop={"10px"}
          >
            Please note that HerVest loans are only eligible to Lagos residents
          </Text>
        </Box>
      </div>

      <Flex justify="center" mt="20px" align="center">
        <Box
          w={["100%", "80%", "60%"]}
          boxShadow="0px 4px 12px 0px rgba(197, 197, 197, 0.25)"
          borderRadius="35px"
          border="0.5px solid #EFEAED"
          p={["20px 30px", "25px 35px", "45px 55px 45px 55px"]}
        >
          <Text
            p="10px 10px 20px 10px"
            fontSize={["18px", "22px"]}
            fontWeight={["500", "700"]}
            color="#540A45"
            fontFamily="SatoshiBold"
          >
            Get Started with Your Loan Application{" "}
          </Text>
          <RadioGroup
            defaultValue="bvn"
            onChange={handleRadioChange}
            value={proceedMethod}
          >
            <HStack mt="30px" spacing={[2, 3, 5]}>
              <Box
                onClick={() => setProceedMethod("hervest")}
                border={
                  proceedMethod === "hervest"
                    ? "1px solid #5B2E4F"
                    : "1px solid #EFEAED"
                }
                borderRadius="16px"
                p="20px 25px 20px 20px"
              >
                <HStack justify="space-between">
                  <Img boxSize="30px" src={hervestIcon} objectFit="cover" />
                  <Radio value="hervest" color="#5B2E4F"></Radio>
                </HStack>
                <Text
                  fontSize={["14px", "18px"]}
                  fontWeight={["500", "700"]}
                  color="#540A45"
                  fontFamily="SatoshiBold"
                  pt="10px"
                >
                  Are you an existing HerVest user?{" "}
                </Text>
                <Text
                  fontSize={["14px", "16px"]}
                  fontWeight={["500"]}
                  color="#33343D"
                  fontFamily="SatoshiBold"
                >
                  Enter your HerVest app login details to continue{" "}
                </Text>
              </Box>
              <Box
                onClick={() => setProceedMethod("bvn")}
                border={
                  proceedMethod === "bvn"
                    ? "1px solid #5B2E4F"
                    : "1px solid #EFEAED"
                }
                borderRadius="16px"
                p="20px 25px 20px 20px"
              >
                <HStack justify="space-between">
                  <GiFingerPrint size={30} color="#5B2E4F" />
                  <Radio value="bvn" color="#5B2E4F"></Radio>
                </HStack>
                <Text
                  fontSize={["14px", "18px"]}
                  fontWeight={["500", "700"]}
                  color="#540A45"
                  fontFamily="SatoshiBold"
                  pt="10px"
                >
                  Proceed With BVN
                </Text>
                <Text
                  fontSize={["14px", "16px"]}
                  fontWeight={["500"]}
                  color="#33343D"
                  fontFamily="SatoshiBold"
                >
                  Enter your 10 digits BVN Number to continue
                </Text>
              </Box>
            </HStack>
          </RadioGroup>
          {error && (
            <Text
              fontSize={["12px", "14px"]}
              fontWeight={["500"]}
              color="#FF0000"
              fontFamily="SatoshiBold"
              pt="10px"
              textAlign={"right"}
            >
              {error}
            </Text>
          )}
          <Divider
            mt="30px"
            color="#666666"
            opacity="85%"
            height="2px"
            width="100%"
            borderBottom="2px color=1px solid #5B2E4F1F"
          />
          <Flex justify="flex-end" mt="10px">
            <EditModal />
            <Button
              ml="5px"
              type="submit"
              bg="#5B2E4F"
              borderRadius="6px"
              fontSize="12px"
              fontWeight="500"
              color="white"
              p="13px 52px"
              _hover={{
                color: "#5B2E4F",
                border: "1px solid #5B2E4F",
                bg: "white",
              }}
              onClick={proceedMethodHandler}
            >
              GET STARTED
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default LandingPage;

/**
 * @param {*} originalData
 * @returns A formatted object of the data received from backed after fetching application with Request ID
 */

const modifyData = (originalData) => {
  if (!originalData) return {};
  const modifiedObject = {};
  for (const key in originalData) {
    if (
      originalData[key] === null ||
      originalData[key] === "null" ||
      originalData[key] === "[object Object]"
    ) {
      modifiedObject[key] = "";
    } else {
      modifiedObject[key] = originalData[key];
    }
  }
  return modifiedObject;
};
