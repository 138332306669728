import {
  Box,
  Flex,
  Text,
  Divider,
  HStack,
  VStack,
  Img,
  Button,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import flag from "../../assets/flag.svg";
import { IoIosArrowDown } from "react-icons/io";
import { updateField, editLoan, requestLoan } from "../../features/loanSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAnalytics, logEvent } from "firebase/analytics";
import { fbAnalyticsApp } from "../../fbanalytics";
import * as amplitude from '@amplitude/analytics-browser';


const NextofKin = ({ setCurrentScreen }) => {
  const request_id = localStorage.getItem("request_id");

  const allLoanData = useSelector((state) => state.loanData);
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const initialvalues = {
    fullName: allLoanData?.nok_name || "",
    phoneNumber: allLoanData?.nok_phone || "",
    dob: allLoanData?.nok_dob.split(" ")[0] || "",
    placeOfWork: allLoanData?.nok_place_of_work || "",
    occupation: allLoanData?.nok_occupation || "",
    email: allLoanData?.nok_email || "",
    officePhoneNumber: allLoanData?.nok_office_phone || "",
    officeAddress: allLoanData?.nok_office_address || "",
    relationship: allLoanData?.nok_relationship || "",
    guarantor_email: allLoanData?.guarantor_email || "",
    guarantor_name: allLoanData?.guarantor_name || "",
  };

  const validationSchemaData = {
    fullName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Only alphabetical characters are allowed")
      .required("Required"),
    phoneNumber: Yup.number()
      .required("Phone number is required")
      .test(
        "len",
        "Phone number should be 11 characters",
        (val) => val.toString().length === 10
      ),
    email: Yup.string()
      .required("Email is required")
      .matches(emailRegex, "Invalid email address"),
    placeOfWork: Yup.string().required("Required"),
    occupation: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Only alphabetical characters are allowed")
      .required("Required"),
    officeAddress: Yup.string(),
    relationship: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Only alphabetical characters are allowed")
      .required("Required"),
    guarantor_name:
      allLoanData?.type === "business" && parseInt(allLoanData?.amount) > 500000
        ? Yup.string().required("Required")
        : Yup.string(),
    guarantor_email:
      allLoanData?.type === "business" && parseInt(allLoanData?.amount) > 500000
        ? Yup.string()
            .required("Required")
            .matches(emailRegex, "Invalid email address")
        : Yup.string(),
    dob: Yup.date().max(new Date(), "Birthday can't be in the future"),
  };

  const submitBtn = (values, { setSubmitting }) => {
    const data = {
      nok_name: values.fullName,
      nok_dob: values.dob,
      nok_phone: values.phoneNumber,
      nok_place_of_work: values.placeOfWork,
      nok_occupation: values.occupation,
      nok_office_phone: values.officePhoneNumber,
      nok_office_address: values.officeAddress,
      nok_email: values.email,
      nok_relationship: values.relationship,
      guarantor_name: values.guarantor_name,
      guarantor_email: values.guarantor_email,
      // completed: 0
    };
    const analytics = getAnalytics(fbAnalyticsApp);
    logEvent(analytics, 'SUBMITTED_NEXT_OF_KIN_FORM', {
      "nok_name": values.fullName,
      "nok_dob": values.dob,
      "nok_phone": values.phoneNumber,
    });

    amplitude.track('SUBMITTED_NEXT_OF_KIN_FORM',
    {
      "nok_name": values.fullName,
      "nok_dob": values.dob,
      "nok_phone": values.phoneNumber,
    });

    dispatch(updateField(data));
    setCurrentScreen(4);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //SAVE APPLICATION
  const saveDetails = async (values) => {
    const data = {
      nok_name: values.fullName,
      nok_dob: values.dob,
      nok_phone: values.phoneNumber,
      nok_place_of_work: values.placeOfWork,
      nok_occupation: values.occupation,
      nok_office_phone: values.officePhoneNumber,
      nok_office_address: values.officeAddress,
      nok_email: values.email,
      nok_relationship: values.relationship,
      guarantor_name: values.guarantor_name,
      guarantor_email: values.guarantor_email,
      // completed: 0
    };

    const updatedData = { ...allLoanData, ...data };

    setLoading(true);
    dispatch(updateField(updatedData));
    if (request_id) {
      dispatch(editLoan(updatedData)).then((res) => {
        if (res?.payload.status === true) {
          toast({
            title: "Loan successfully saved",
            description: "Your application has been saved",
            position: "top-left",
            status: "success",
            duration: 2000,
            isClosable: true,
          });

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setLoading(false);
          localStorage.removeItem("wrongnin");
          return;
        } else {
          toast({
            title: "Loan not saved",
            description: "An error occurred. Please try again later",
            position: "top-left",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
          setLoading(false);
        }
      });
      return;
    }
    dispatch(requestLoan(updatedData)).then((res) => {
      if (res?.payload.status === true) {
        toast({
          title: "Loan successfully saved",
          description: "Your application has been saved",
          position: "top-left",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setLoading("notloading");
        return;
      } else {
        toast({
          title: "Loan not saved",
          description: "An error occurred. Please try again later",
          position: "top-left",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    });
  };

  const goBack = () => {
    setCurrentScreen(2);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /**
   * For Field s with Error, The User get automatically scrolled to the point of error
   * @param {Object} errors A Formik form errors
   * @returns {Array}
   * @description Using the dot nottation principle
   */
  const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (!value) return;

        const nextKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === "object") {
          transformObjectToDotNotation(value, nextKey, result);
        } else {
          result.push(nextKey);
        }
      });

      return result;
    };

    return transformObjectToDotNotation(formikErrors);
  };

  /**
   *
   * @returns  the scroll behaviour to the point of error
   */
  const ScrollToFieldError = ({
    scrollBehavior = { behavior: "smooth", block: "center" },
  }) => {
    const { submitCount, isValid, errors } = useFormikContext();
    useEffect(() => {
      if (isValid) return;

      const fieldErrorNames = getFieldErrorNames(errors);
      if (fieldErrorNames.length <= 0) return;

      const element = document.querySelector(
        `input[name='${fieldErrorNames[0]}']`
      );
      if (!element) return;
      element.scrollIntoView(scrollBehavior);
    }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps
    return null;
  };

  return (
    <Flex justify="center">
      <Box
        w={["100%", "80%", "60%"]}
        boxShadow="0px 4px 12px 0px #D7D7D740"
        border="0.5px solid #EFEAED"
        borderRadius="10px"
      >
        <Text
          p="20px"
          fontSize={["12px", "14px"]}
          fontWeight={["500", "700"]}
          color="#33343D"
        >
          NEXT OF KIN INFORMATION
        </Text>
        <Divider
          color="#666666"
          opacity="85%"
          height="2px"
          width="100%"
          borderBottom="2px color=1px solid #5B2E4F1F"
        />
        <Box p={["10px", "20px"]}>
          <Formik
            initialValues={initialvalues}
            validationSchema={Yup.object(validationSchemaData)}
            onSubmit={submitBtn}
          >
            {({ values }) => {
              return (
                <Form>
                  <ScrollToFieldError />
                  <VStack spacing={8}>
                    <Box w="100%">
                      <Text
                        color="#33343D"
                        fontWeight="500"
                        fontSize="13px"
                        opacity="0.8px"
                      >
                        <label htmlFor="fullName">Full Name*</label>
                      </Text>
                      <Field
                        className="form-input"
                        name="fullName"
                        placeholder="Enter FullName"
                        type="text"
                      />
                      <Text color="red" fontSize="11px">
                        <ErrorMessage name="fullName" />
                      </Text>
                    </Box>
                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                    >
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="phoneNumber">Phone Number*</label>
                        </Text>
                        <HStack
                          width="100%"
                          p="15px 10px"
                          background="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                          border="0.17px solid rgba(91, 46, 79, 1)"
                          borderRadius="6px"
                        >
                          <HStack spacing={1} pl="5px" mr="10px">
                            <Img
                              src={flag}
                              borderRadius="5px"
                              objectFit="contain"
                            />
                            <Text
                              color="#33343D"
                              fontSize="12px"
                              fontWeight="500"
                              opacity="50%"
                            >
                              +234
                            </Text>
                            <Box color="#848481">
                              <IoIosArrowDown />
                            </Box>
                          </HStack>
                          <Field
                            className="form-input-phonenumber"
                            name="phoneNumber"
                            placeholder="Phone Number"
                            type="number"
                          />
                        </HStack>
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="phoneNumber" />
                        </Text>
                      </Box>
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="dob">Date of Birth</label>
                        </Text>
                        <Field
                          className="form-input"
                          name="dob"
                          placeholder="DD/MM/YYYY"
                          type="date"
                          max="2006-01-01"
                        />
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="dob" />
                        </Text>
                      </Box>
                    </Stack>

                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                    >
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="80px"
                        >
                          <label htmlFor="placeOfWork">Place of Work*</label>
                        </Text>
                        <Field
                          className="form-input"
                          name="placeOfWork"
                          placeholder="Enter Place of Work"
                          type="text"
                        />
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="placeOfWork" />
                        </Text>
                      </Box>
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="80px"
                        >
                          <label htmlFor="occupation">Occupation*</label>
                        </Text>
                        <Field
                          className="form-input"
                          type="text"
                          name="occupation"
                          placeholder="occupation"
                        ></Field>
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="occupation" />
                        </Text>
                      </Box>
                    </Stack>

                    <Stack
                      direction={["column", "row"]}
                      w="100%"
                      spacing={6}
                      justify="space-between"
                    >
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="email">Email Address*</label>
                        </Text>
                        <Field
                          className="form-input"
                          name="email"
                          placeholder="Email address"
                          type="text"
                        />
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="email" />
                        </Text>
                      </Box>
                      <Box w={["100%", "50%"]}>
                        <Text
                          color="#33343D"
                          fontWeight="500"
                          fontSize="13px"
                          opacity="0.8px"
                        >
                          <label htmlFor="relationship">
                            Relationship to borrower*
                          </label>
                        </Text>
                        <Field
                          className="form-input"
                          name="relationship"
                          placeholder="relationship"
                          type="text"
                        ></Field>
                        <Text color="red" fontSize="11px">
                          <ErrorMessage name="relationship" />
                        </Text>
                      </Box>
                    </Stack>

                    <Box w="100%">
                      <Text
                        color="#33343D"
                        fontWeight="500"
                        fontSize="13px"
                        opacity="0.8px"
                      >
                        <label htmlFor="officeAddress">
                          Office/Business Address
                        </label>
                      </Text>
                      <Field
                        as="textarea"
                        className="form-textarea"
                        name="officeAddress"
                        placeholder="Enter Address"
                        type="text"
                      />
                      <Text color="red" fontSize="11px">
                        <ErrorMessage name="officeAddress" />
                      </Text>
                    </Box>
                  </VStack>

                  {allLoanData?.type === "business" &&
                    parseInt(allLoanData?.amount) > 500000 && (
                      <>
                        <Divider
                          mt="30px"
                          height="0.4px"
                          bg="#5B2E4F"
                          width="100%"
                        />
                        <Text
                          p="20px 0px"
                          fontSize={["12px", "14px"]}
                          fontWeight={["500", "700"]}
                          color="#33343D"
                        >
                          GUARANTOR'S INFORMATION
                        </Text>
                        <Stack
                          direction={["column", "row"]}
                          w="100%"
                          spacing={6}
                          justify="space-between"
                        >
                          <Box w={["100%", "50%"]}>
                            <Text
                              color="#33343D"
                              fontWeight="500"
                              fontSize="13px"
                              opacity="80px"
                            >
                              <label htmlFor="guarantor_name">
                                Guarantor's Name*
                              </label>
                            </Text>
                            <Field
                              className="form-input"
                              name="guarantor_name"
                              placeholder="Enter name"
                              type="text"
                            />
                            <Text color="red" fontSize="11px">
                              <ErrorMessage name="guarantor_name" />
                            </Text>
                          </Box>
                          <Box w={["100%", "50%"]}>
                            <Text
                              color="#33343D"
                              fontWeight="500"
                              fontSize="13px"
                              opacity="80%"
                            >
                              <label htmlFor="guarantor_email">
                                Guarantor's Email*
                              </label>
                            </Text>
                            <Field
                              className="form-input"
                              type="text"
                              name="guarantor_email"
                              placeholder="Enter email"
                            ></Field>
                            <Text color="red" fontSize="11px">
                              <ErrorMessage name="guarantor_email" />
                            </Text>
                          </Box>
                        </Stack>
                      </>
                    )}

                  <Divider
                    mt="20px"
                    color="#666666"
                    opacity="85%"
                    height="2px"
                    width="100%"
                    borderBottom="2px color=1px solid #5B2E4F1F"
                  />
                  <HStack spacing={3} justify="flex-end" mt="30px">
                    {allLoanData.isLoading ? (
                      <Button
                        disabled={loading}
                        color="#5B2E4F"
                        fontSize="12px"
                        mr="5px"
                        isLoading
                        border="1px solid #5B2E4"
                        loadingText="saving"
                      >
                        SAVE
                      </Button>
                    ) : (
                      <Button
                        disabled={loading}
                        color="#5B2E4F"
                        fontSize="12px"
                        mr="5px"
                        onClick={() => saveDetails(values)}
                      >
                        SAVE
                      </Button>
                    )}

                    <Button
                      bg="white"
                      borderRadius="6px"
                      fontSize="12px"
                      fontWeight="500"
                      color="#5B2E4F"
                      border="1px solid #5B2E4F"
                      p={["8px 32px", "13px 52px"]}
                      _hover={{
                        color: "white",
                        border: "1px solid #5B2E4F",
                        bg: "#5B2E4F",
                      }}
                      onClick={goBack}
                    >
                      BACK
                    </Button>
                    <Button
                      type="submit"
                      bg="#5B2E4F"
                      borderRadius="6px"
                      fontSize="12px"
                      fontWeight="500"
                      color="white"
                      p={["8px 32px", "13px 52px"]}
                      _hover={{
                        color: "#5B2E4F",
                        border: "1px solid #5B2E4F",
                        bg: "white",
                      }}
                    >
                      NEXT
                    </Button>
                  </HStack>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Flex>
  );
};

export default NextofKin;
