import {
    Box, Flex, Text, Stack, VStack,Divider, Img ,HStack,Button } from '@chakra-ui/react'
import React from 'react'
import FinalformTable from '../Others/FinalformTable'
import ceoSignature from "../../assets/ceoSignature.svg"
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { getAnalytics, logEvent } from "firebase/analytics";
import { fbAnalyticsApp } from "../../fbanalytics";
import * as amplitude from '@amplitude/analytics-browser';

const FinalOfferForm = () => {    
    const initialvalues = {
        borrowerFullName: "",
        interpreterFullName: "",
        signature:""
    }
    const validationSchemaData = {
        borrowerFullName: Yup.string().required('required'),
        interpreterFullName: Yup.string().required('required'),
        signature: Yup.string().required('required'),
    }

    const submitBtn = (values, { setSubmitting }) => {
        const analytics = getAnalytics(fbAnalyticsApp);
        logEvent(analytics, 'VIEWED_FINAL_OFFER', {
            "CLICKED_FINAL_OFFER_BTN": "YES"
          });
          amplitude.track("VIEWED_FINAL_OFFER", {"": "YES"});
    }
  return (
    <Flex justify="center" w="100%">
          <Box w={["100%", "80%", "60%"]} boxShadow="0px 4px 12px 0px #D7D7D740" border="0.5px solid #EFEAED" borderRadius="10px">
              <Box p={["10px", "30px 30px 10px 30px"]}>
                <Text fontSize="14px" fontWeight="700"  color="#33343D">
                    Dear Adenike Adebayo,
                </Text>
                  <Text color="#540A45" pt="10px" fontWeight="700" fontSize={["16px", "18px"]} textAlign="center" fontFamily="SatoshiBolder">
                    OFFER OF CREDIT FACILITY
                </Text>
                  <Text fontSize={["12px", "14px"]} fontWeight="500" lineHeight="21px" align="justify" color="#33343D" p={["10px 0", "20px 0"]}>
                    We are pleased to advise you that the Management of HerVest for Women Limited has approved your request for a loan facility under the following terms and conditions:

                </Text>
                <FinalformTable />
                <Text color="#33343D" pt="30px" fontSize="14px" fontWeight="700" fontFamily="SatoshiBold">OTHER CONDITIONS:</Text>
            </Box>
              
            <Divider color="#5B2E4F" opacity="0.85px" height="2px" width="100%" />
              <VStack align="baseline" spacing={5} mt="10px" mb={["5px","10px"]} p={["20px 5px 10px 10px", "30px 30px 10px 30px"]}>
                  <Text fontSize={["12px", "14px"]} fontWeight="500" align="justify" color="#33343D" lineHeight="21px" fontFamily="SatoshiBold">
                    ⦁	You hereby indemnify HerVest against any loss that might be incurred by them as a result of any irregularity or incompleteness in the information contained in any documents submitted by you.

                 </Text>
                  <Text fontSize={["12px", "14px"]} fontWeight="500" align="justify" color="#33343D" lineHeight="21px" fontFamily="SatoshiBold">
                    ⦁	No change in the business operations and conditions (financial or otherwise) or other prospects shall affect the full repayment of the facility at maturity.
                 </Text>
                  <Text fontSize={["12px", "14px"]} fontWeight="500" align="justify" color="#33343D" lineHeight="21px" fontFamily="SatoshiBold">
                    ⦁	This facility shall be utilized strictly for the purpose stated above and on no condition(s) should it be utilized for any other purpose.
                  </Text>
                  <Text fontSize={["12px", "14px"]} fontWeight="500" align="justify" color="#33343D" lineHeight="21px" fontFamily="SatoshiBold">
                    ⦁	Funds received into the account when the principal and/or interest are past due will be applied first to the overdue interest before the outstanding principal amount.

                 </Text>
                  <Text fontSize={["12px", "14px"]} fontWeight="500" align="justify" color="#33343D" lineHeight="21px" fontFamily="SatoshiBold">

                    ⦁	The approval and disbursement of loans is at the sole discretion of HerVest based on its internal risk assessment.
                </Text>
                  <Text color="#33343D" pt={["10px", "20px"]} fontSize="14px" fontWeight="700" fontFamily="SatoshiBold">VALIDITY CLAUSE:</Text>
            </VStack>
            <Divider color="#5B2E4F" opacity="0.85px" height="2px" width="100%" />
              <VStack align="baseline" spacing={6} p={["20px 10px 10px 10px", "30px 30px 10px 30px"]}>
                  <Text fontSize={["12px", "14px"]} fontWeight="500" align="justify" color="#33343D" lineHeight="21px" fontFamily="SatoshiBold">
                    This offer will lapse at the instance of HerVest if not accepted within 3 (three) days from the date of this letter.

                    
                </Text>
                  <Text fontSize={["12px", "14px"]} fontWeight="500" align="justify" color="#33343D" lineHeight="21px" fontFamily="SatoshiBold">
                    If the terms and conditions of this facility are acceptable to you, kindly sign a copy of this offer letter and return same to us.

                    
                </Text>
                  <Text fontSize={["12px", "14px"]} fontWeight="500" align="justify" color="#33343D" lineHeight="21px" fontFamily="SatoshiBold">
                    We thank you for your continued patronage and look forward to a more fruitful banking relationship.

                
                </Text>
                <Box>
                    <Text fontSize="14px" fontWeight="500" align="justify" color="#33343D" lineHeight="21px" fontFamily="SatoshiBold">
                        Yours Faithfully,
                        </Text>
                      <Text fontSize={["12px", "14px"]} fontWeight="700" align="justify" color="#33343D" lineHeight="21px" fontFamily="SatoshiBolder">  For: HerVest for Women Limited.</Text>
                </Box>
                <Text color="#33343D" fontSize="14px" fontWeight="500" fontFamily="SatoshiBold">Authorized Signatory</Text>
                <Img src={ceoSignature} objectFit="contain"/>
                <Text fontSize="14px" fontWeight="700" color="#33343D" fontFamily="SatoshiBold">
                    ACCEPTANCE:
                </Text>
            </VStack>
            <Divider color="#5B2E4F" opacity="0.85px" height="2px" width="100%" />
            <Formik
                  initialValues={initialvalues}
                  validationSchema={Yup.object(validationSchemaData)}
                  onSubmit={submitBtn}>
                {
                    ({values})=>{
                    return(
                        <Form>
                            <VStack align="baseline" w="100%" p={["20px 10px 10px 10px", "30px 30px 10px 30px"]}>
                                <Text fontSize={["12px", "14px"]} fontWeight="500" align="justify" color="#33343D" lineHeight="21px" fontFamily="SatoshiBold">Please signify your acceptance of the above terms and conditions by signing the acceptance column below and returning the second copy of this letter to the HerVest.</Text>
                                <Stack direction={["column", "row"]} w="100%" spacing={6} justify="space-between" mt="30px">
                                    <Box w={["100%", "50%"]}>
                                        <Text color="#33343D" fontWeight="500" fontSize="13px" opacity="0.8px"><label htmlFor="borrowerFullName">Borrower's Full Name</label></Text>
                                        <Field className="form-input" name="borrowerFullName" placeholder="Enter Name" type="text" />
                                        <Text color="red" fontSize="11px"><ErrorMessage name="borrowerFullName" /></Text>
                                    </Box>
                                    <Box w={["100%", "50%"]} >
                                        <Text color="#33343D" fontWeight="500" fontSize="13px" opacity="0.8px" mb="5px"><label htmlFor="signature" fontFamily="SatoshiBold">Upload Signature</label></Text>
                                        <Flex
                                            bg="linear-gradient(0deg, #F8F1F6, #F8F1F6),linear-gradient(0deg, #5B2E4F, #5B2E4F)"
                                            w="100%" pos="relative" justify="center" align="center" borderRadius="7px" h="50px">
                                            <VStack h="100%" align="center" justify="center" >
                                                <Text fontSize="13px" color="#33343D" opacity="50%" fontWeight="500">Upload Photo</Text>
                                                <Field className="upload-input hidden" name="signature" type="file" />
                                            </VStack>
                                        </Flex>
                                        {values.signature &&
                                            <Text fontSize="12px" color="#540A45" fontWeight={700}>{values.signature}</Text>}
                                        <Text color="red" fontSize="11px"><ErrorMessage name="signature" /></Text>
                                    </Box>
                                </Stack>
                                <Text mt="20px" fontSize="14px" fontWeight="700" color="#33343D" fontFamily="SatoshiBold">
                                    ILLITERATE JURAT
                                </Text>
                            </VStack>
                            <Divider color="#5B2E4F" opacity="0.85px" height="2px" width="100%" />
                            <VStack p={["10px", "30px 30px 10px 30px"]} spacing={5} align="baseline">
                                <Text fontWeight="500"
                                    fontSize={["12px", "14px"]}
                                    lineHeight="21px"
                                    align="justify" fontFamily="SatoshiBold">
                                    The contents of this Offer Letter haven been first read and interpreted to the Borrower by
                                    ……………………………………. (Name of interpreter) in	language and when
                                    he/she appeared to have perfectly understood and approved the contents, appended his/her signature or affixed his/her right thumb as above.

                                </Text>
                                <Box w={["100%", "50%"]}>
                                    <Text color="#33343D" fontWeight="500" fontSize={["11px", "13px"]} opacity="0.8px"><label htmlFor="interpreterFullName">interpreter's Full Name*</label></Text>
                                    <Field className="form-input" name="interpreterFullName" placeholder="Enter FullName" type="text" />
                                    <Text color="red" fontSize="11px"><ErrorMessage name="interpreterFullName" /></Text>
                                </Box>
                            </VStack>
                            <Divider mt="30px" height="0.4px" bg="#5B2E4F" width="100%" />
                            <HStack justify="flex-end" mt="20px" ml="5px" mr="5px">
                                <Button type="submit" bg="white" border="1px solid #5B2E4F" borderRadius="6px" fontSize="12px" fontWeight="500" color="#5B2E4F" p="13px 52px">
                                    DECLINE
                                </Button>
                                <Button type="submit" bg="#5B2E4F" borderRadius="6px" fontSize="12px" fontWeight="500" color="white" p="13px 52px" _hover={{ color: "#5B2E4F", border: "1px solid #5B2E4F", bg: "white" }}>
                                    ACCEPT
                                </Button>
                            </HStack>
                        </Form>
                        )
                    }
                }
            </Formik>
       
        </Box>
    </Flex>
  )
}

export default FinalOfferForm
